
import { mapGetters, mapActions } from "vuex";
import { arrayConverter } from "@/utils";
export default {
  name: "Hotel",
  props: { hotel: Object },
  data() {
    return { showItems: 6 };
  },
  computed: {
    ...mapGetters(["facilities"]),
    images() {
      return [
        ...arrayConverter(this.hotel.image),
        ...arrayConverter(this.hotel.images),
      ];
    },
    getFacilities() {
      return this.facilities.filter(({ _id }) =>
        (this.hotel.facilities || []).some((id) => _id === id)
      );
    },
  },
  mounted() {
    this.fetchAllFacilitiesOnce();
    this.$nextTick(() => {
      this.showNav();
    });
  },
  methods: {
    ...mapActions(["fetchAllFacilitiesOnce"]),
    getFacility(id) {
      return this.facilities.find(({ _id }) => _id === id);
    },
    showNav() {
      const prevButton = this.$refs.prevButton;
      const nextButton = this.$refs.nextButton;

      if (prevButton) {
        const prevSpan = prevButton.closest("span");
        if (prevSpan) {
          prevSpan.removeAttribute("style");
        }
      }

      if (nextButton) {
        const nextSpan = nextButton.closest("span");
        if (nextSpan) {
          nextSpan.removeAttribute("style");
        }
      }
    },
    showAll() {
      this.showItems = this.showItems === 999 ? 6 : 999;
    },
  },
};
