
import Dot from "@/static/icons/newsletter-dot.svg";
import Bg from "@/static/icons/newsletter-bg.svg";
export default {
  name: "Newsletter",
  components: { Dot, Bg },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$nuxt.$emit(
            "success",
            "Email are successfully added to the list"
          );
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
