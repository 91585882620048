
import vClickOutside from "v-click-outside";
import { mapActions, mapGetters } from "vuex";
import {
  addDate,
  compareDate,
  endDate,
  onlyDate,
  startDate,
  validateDate,
} from "@/utils";
export default {
  name: "Search",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      show: "",
      form: {
        search: "",
        checkIn: addDate(2),
        checkOut: addDate(4),
        adult: 2,
        child: 0,
        room: 1,
      },
      errors: {},
      searchTimeout: null,
    };
  },
  computed: {
    ...mapGetters(["searchData"]),
    locations() {
      return this.searchData?.locations || [];
    },
    properties() {
      return this.searchData?.properties || [];
    },
    guestAndRoom() {
      const adult = this.form.adult > 0 ? `${this.form.adult} Adults` : "";
      const child = this.form.child > 0 ? `, ${this.form.child} Child` : "";
      const room = this.form.room > 0 ? `, ${this.form.room} Room` : "";
      return adult + child + room;
    },
  },
  watch: {
    "form.search"() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.refetchDestinationProperty();
      }, 500);
    },
    "form.checkIn"(val) {
      if (endDate(val) >= endDate(this.form.checkOut))
        this.form.checkOut = addDate(1, val);
    },
    "form.checkOut"(val) {
      if (endDate(val) <= endDate(this.form.checkIn))
        this.form.checkIn = addDate(-1, val);
    },
  },
  mounted() {
    this.getSearchData();
    this.setData();
  },
  methods: {
    ...mapActions(["getSearchData"]),
    disabledCheckInDate(date) {
      const today = startDate();
      const maxDate = endDate(addDate(180));

      return date < today || date > maxDate;
    },
    disabledCheckOutDate(date) {
      const today = startDate(addDate(1));
      const maxDate = endDate(addDate(180));

      return date < today || date > maxDate;
    },
    refetchDestinationProperty() {
      this.getSearchData(this.form.search);
    },
    showFalse() {
      setTimeout(() => {
        this.show === "destination" ? (this.show = "") : "";
      }, 300);
    },
    submit() {
      const targetRoute = {
        name: "search-hotels",
        query: {
          ...this.form,
          checkIn: onlyDate(this.form.checkIn),
          checkOut: onlyDate(this.form.checkOut),
        },
      };

      const { pathname, search } = window.location;
      const targetUrl = this.$router.resolve(targetRoute).href;
      const currentUrl = pathname + search;

      if (currentUrl !== targetUrl) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
      }
    },
    setData() {
      const { name, query } = this.$route;
      if (name === "search-hotels") {
        const {
          search = "",
          adult = 1,
          child = 0,
          room = 1,
          checkIn = addDate(2),
          checkOut = addDate(4),
        } = query;
        this.form = {
          search,
          adult,
          child,
          room,
          checkIn: validateDate(checkIn, addDate(2)),
          checkOut: validateDate(checkOut, addDate(4)),
        };
      }
    },
  },
};
