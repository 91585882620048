
import { meta } from "@/utils";
import axios from "axios";

export default {
  name: "IndexPage",
  auth: false,
  head() {
    return {
      title: `All tours and travel tips and information - ${this.$pageTitle}`,
      meta: meta(),
    };
  },
  data() {
    return {
      locations: [],
      hotels: [],
    };
  },
  async asyncData({ error, store }) {
    try {
      const res = await axios.get(
        `${store.getters.apiUrl}/api/fetch/home-data`
      );
      if (res.data) {
        const { locations, hotels } = res.data;
        return { locations, hotels };
      } else {
        error({ statusCode: 404, message: "Not found" });
      }
    } catch (err) {
      console.error("Error fetching home data:", err.message);
      console.error("Error details:", err);
      error({
        statusCode: 500,
        message: "Something went wrong. Please try again",
      });
    }
  },
};
