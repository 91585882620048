import { render, staticRenderFns } from "./index.vue?vue&type=template&id=22a9fe7a"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/vercel/path0/components/Home/Hero.vue').default,HomeSearch: require('/vercel/path0/components/Home/Search.vue').default,HomeDestination: require('/vercel/path0/components/Home/Destination.vue').default,HomeHotel: require('/vercel/path0/components/Home/Hotel.vue').default,HomeService: require('/vercel/path0/components/Home/Service.vue').default,HomeFacilities: require('/vercel/path0/components/Home/Facilities.vue').default,HomeDeals: require('/vercel/path0/components/Home/Deals.vue').default,HomeNewsletter: require('/vercel/path0/components/Home/Newsletter.vue').default})
