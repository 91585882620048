
import LineIcon from "@/static/icons/line.svg";
export default {
  name: "hero",
  components: { LineIcon },
  data() {
    return {
      show: false,
    };
  },
};
