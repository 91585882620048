
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HomeFacilities",
  computed: {
    ...mapGetters(["facilities"]),
    sortFacilities() {
      const facility = [...this.facilities];
      return facility
        .filter(
          (facility) => facility.icon !== '<i class="fa-solid fa-check"></i>'
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    this.fetchAllFacilitiesOnce();
  },
  methods: {
    ...mapActions(["fetchAllFacilitiesOnce"]),
  },
};
