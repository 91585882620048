
export default {
  name: "Hstack",
  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    limitCross: Boolean,
  },
  computed: {
    value: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    triggerLimit(name) {
      if (this.limitCross) name === "minLimit" ? this.value-- : this.value++;
      this.$emit(name);
    },
  },
};
