
export default {
  name: "HomeService",
  data() {
    return {
      services: [
        {
          icon: "fas fa-headset text-orange-600",
          title: "24x7 Help",
          subtitle:
            "We are available anytime you need us. Our support team is here for you around the clock.",
          bg: "bg-orange-100",
        },
        {
          icon: "far fa-credit-card text-green-600",
          title: "Payment Trust",
          subtitle:
            "Your payments are always safe with us. We use secure and reliable payment methods.",
          bg: "bg-green-100",
        },
        {
          icon: "far fa-newspaper text-cyan-600",
          title: "Easy Booking",
          subtitle:
            "Booking process made simple for you. Our system is designed to make booking as simple as possible.",
          bg: "bg-cyan-100",
        },
        {
          icon: "fas fa-percent text-indigo-600",
          title: "Exclusive Offers",
          subtitle:
            "Take advantage of our exclusive offers. Enjoy special discounts and promotions.",
          bg: "bg-indigo-100",
        },
      ],
    };
  },
};
